import { TonalProfileType } from "./types"

export interface ApiResponse  {
    error: boolean
    payload: any
    info: string
}

export interface SignedUrlResponse  {
    fileName: string
    signedUrl: string
    bucketName: string
}


export interface Feedback {
    comment: string,
    rating: number,
}

export enum ClippingStates {
    NONE = 'NONE',
    MINOR = 'MINOR',
    MAJOR = 'MAJOR'
}


export interface Diagnosis {
    bit_depth: number;
    clipping: ClippingStates;
    if_master_drc: string;
    if_master_loudness: string;
    if_mix_drc: string;
    if_mix_loudness: string;
    mono_compatible: boolean;
    musical_style: string;
    phase_issues: boolean;
    sample_rate: number;
    stereo_field: string;
    tonal_profile: TonalProfileType;
    summary: string;
    peak_loudness_dbfs: number
    integrated_loudness_lufs: number
}

export const defaultDiagnosisObj: Diagnosis = {
    bit_depth: 0,
    clipping: ClippingStates.NONE,
    integrated_loudness_lufs: 0,
    peak_loudness_dbfs: 0,
    if_master_drc: '',
    if_master_loudness: '',
    if_mix_drc: '',
    if_mix_loudness: '',
    mono_compatible: false,
    musical_style: 'foo',
    phase_issues: true,
    sample_rate: 0,
    stereo_field: '',
    tonal_profile: {
        high_mid_frequency: 'LOW',
        bass_frequency: 'MEDIUM',
        high_frequency: 'HIGH',
        low_mid_frequency: 'LOW'
    },
    summary: "testing 123 testing 123"
};