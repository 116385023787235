import './styles.css'

import React from "react"
import { Tooltip } from "react-tooltip";

interface ResultProps {
    name: string;
    value: string | boolean | number
}

// capitalises the first letter of every word
const toTitleCase = (phrase: string) => {
    return phrase
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};
  
/**
 * Converts value into a string and doctors
 * values where necessary. 
 * @param value 
 * @param name 
 * @returns 
 */
function prepareValue(value: string | boolean | number, name: string):string {

    // if bit depth === 0 (is mp3)
    if(name == "Bit Depth") {
        if(!value) return '-' 
    }

    // append '+' if positive
    if(name == "True Peak Loudness") {
        if(value > 0) {
            return ("+" + value).toString().replaceAll('_', ' ')
        }
    }

    // doctor clipping value
    if(name == 'Clipping') {
        if(value === 'MINOR') return 'Yes (Minor)'
        else if (value === 'MAJOR') return 'Yes'
        else return 'None'
    }

    // if boolean (phase issues, mono-compat)
    if(!value) return 'No'
    else if (value === true) {
        return 'Yes'
    }
    
    // if "Stereo Field or Genre"


    const removedUnderscores = value.toString().replaceAll('_', ' ')
    return toTitleCase(removedUnderscores)
}


/**
 * Creates a tootlip for the correct Result
 * @param name 
 * @returns 
 */
function prepareTooltip(name: string) {
    let text = ""

    switch(name) { 
    case "Genre": { 
        text+="The predicted musical style of the audio track you have submitted."
        break; 
    } 
    case "Bit Depth": { 
        text+="The number of bits used to represent each audio sample, affecting the dynamic range and precision of digital audio. Higher bit depth allows for more accurate sound reproduction. You bit depth should be at least 16 bit, but can be 24 bit."
        break; 
    } 
    case "Clipping": { 
        text+="Distortion caused by an audio signal exceeding the system’s limit, resulting in a harsh or distorted sound. Prevent by managing volume and using audio tools."
        break; 
    } 
    case "Loudness": { 
        text+="A measurement of perceived audio loudness in Loudness Units Full Scale (LUFS), accounting for human hearing sensitivity. Used to maintain consistent volume levels across different audio sources."
        break; 
    } 
    case "Mono Compatibility": { 
        text+="The ability of a stereo audio mix to maintain balance, phase coherence, and sound quality when collapsed to a single (mono) channel. Important for ensuring consistent playback on various systems."
        break; 
    } 
    case "True Peak Loudness": { 
        text+="True Peak Loudness measures the highest amplitude in an audio waveform, considering intersample peaks that regular peak measurements might miss. Expressed in dBTP or dBFS, it helps prevent clipping and distortion, ensuring optimal audio quality and compatibility with different playback systems."
        break; 
    } 
    case "Phase Issues": { 
        text+="Timing differences between audio waveforms, causing cancellation or reinforcement of frequencies, which may lead to a weak or hollow sound. Resolve by adjusting the timing or using phase correction tools."
        break; 
    } 
    case "Stereo Field": { 
        text+="The perceived width and depth of an audio mix, created by differences in level, timing, and frequency content between the left and right channels. Enhances listener immersion and spatial experience."
        break; 
    } 
    case "Sample Rate": { 
        text+="The number of audio samples captured per second in digital audio, affecting the frequency range and audio quality. Higher sample rates result in more accurate sound reproduction. This should be at least CD-Quality i.e. 44100 Hz."
        break; 
    } 
    case "Integrated Loudness": { 
        text+="A measure of the average perceived loudness of an audio signal over its entire duration. It gives a single value that represents the overall loudness level of the audio content, and is used to ensure consistent loudness across different programs and content."
        break; 
    } 
    }
    return text
}

/**
 * Determines if a Result should show a warning
 * depending on the value.
 * @param name 
 * @param value 
 * @returns 
 */
function needsWarning(name: string, value: string | number | boolean): boolean {

    switch(name) { 

    case "Bit Depth": { 
        if(value === 0) return false
        if(value < 16) return true

        break; 
    } 
    case "Clipping": { 
        if(value === "MINOR" || value === "MAJOR") return true
        break; 
    } 
    case "Mono Compatibility": { 
        if(!value) return true
        break; 
    } 
    case "DC Offset": { 
        if(value) return true
        break; 
    } 
    case "Phase Issues": { 
        if(value) return true
        break; 
    } 
    case "Stereo Field": { 
        if(value === "WIDE" || value === "NARROW") return true
        break; 
    } 
    case "Sample Rate": { 
        if(value < 44100) return true
        break; 
    } 
    }

    return false

}

function ResultItem ({ name, value }: ResultProps) {

    return (
        <>
            <div className='item-container'
                data-tooltip-id="item-tooltip" 
                data-tooltip-content={prepareTooltip(name)}
                data-tooltip-place="top"
            >
                {needsWarning(name, value) ? <div className='warning'>
                    <img src='warn.png' className='warn-image'/>
                </div> : null}
                <div className='data-item-container'>
                    <p className='large-text bold no-margin'>{ prepareValue(value, name) }</p>
                </div>
                <div className='name-container'>
                    <p className='small-text no-margin centre-align off-grey'>{ name }</p>
                </div>
            </div>
            <Tooltip id="item-tooltip" className='popup-content' />
        </>
    )
}

export default ResultItem