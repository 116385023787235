import './styles.css'
import '../../shared/styles/typography.css'
import '../../shared/styles/spacing.css'
import '../../shared/styles/colours.css'

import { getSignedUrl, mixdiagnosis, uploadFile } from '../../helper/client'

import { AppState } from '../../App'
import { Diagnosis } from '../../shared/interfaces'
import SocialsList from '../../components/SocialsList'
import TipsCarousel from '../../components/TipsCarousel'
import { useEffect } from 'react'

interface DiagnosingProps {
    setAppState: React.Dispatch<React.SetStateAction<AppState>>
    appState: AppState
    setResponse: React.Dispatch<React.SetStateAction<Diagnosis>>
    file: any
    isMix: boolean
    musicalStyle: string
}

function Diagnosing ({
    setAppState, 
    setResponse,
    file, 
    isMix,
    appState, 
    musicalStyle }:DiagnosingProps) {

    // Type guard before casting to Diagnosis type
    const isDiagnosis = (payload: any): payload is Diagnosis => {
        return payload
    }

    const executeUpload = async () => {
        try {
            // Get signed Url to be used for upload
            const res = await getSignedUrl({ fileName: file.name, audioType: file.type })
    
            // upload file to bucket using signed url
            await uploadFile(res.signedUrl, { file: file })
    
            //  Get the URL of the uploaded file
            const url = `https://storage.googleapis.com/${res.bucketName}/${res.fileName}`;
    
            setAppState(AppState.DIAGNOSING)
    
            const { error, payload } = await mixdiagnosis({ publicUrl: url, isMaster: !isMix, musicalStyle: musicalStyle })
    
            if (error) {
                setAppState(AppState.ERROR)
                return;
            }
    
            if (isDiagnosis(payload)) {
                setResponse(payload)
            }
          
            setAppState(AppState.COMPLETED)
            
        } catch (error) {
            console.error(error)
            setAppState(AppState.ERROR)
        }
    }
    
    useEffect(() => {
        executeUpload()
    }, [])

    return (
        <div className='page-container'>
            <div className='main-content'>
                <img className='load-image' src='ball.gif' alt="loading..." />
                {
                    appState === AppState.UPLOADING ? 
                        <p className='small-text bold'>Uploading Track <span className='off-grey'>(1/2)</span></p> :
                        <p className='small-text bold'>Diagnosing Track <span className='off-grey'>(2/2)</span></p>
                }
                <p className='small-text center off-grey'>This may take several minutes depending on the size of your audio.</p>
                <br />
                <TipsCarousel />
                <br />
                <br />
                <SocialsList />
            </div>
        </div>
    )
}

export default Diagnosing