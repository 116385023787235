import './styles.css'
import '../../shared/styles/typography.css'
import '../../shared/styles/spacing.css'
import '../../shared/styles/colours.css'

import AboutSection from '../../components/AboutSection'
import { AppState } from '../../App'
import DragAndDrop from '../../components/DragAndDrop'
import Footer from '../../components/Footer'
import SocialsList from '../../components/SocialsList'

interface DefaultPageProps {
    setAppState: React.Dispatch<React.SetStateAction<AppState>>
    setUploadedFile: React.Dispatch<React.SetStateAction<any>>
}

function Home ({setAppState, setUploadedFile}: DefaultPageProps) {

    return (
        <>
            <div className='single-page'>
                <div className='main-page-container'>
                    <h1 className='large-text parabole'><span className='large-text parabole roex-blue'>mix check</span> studio</h1>
                    <p className='small-text heavy-opacity medium-text-width'>Mix Check Studio utilises cutting-edge AI technology to analyse your mixes and masters, delivering accurate and actionable feedback to help you identify and fix issues in your music.</p>
                    <DragAndDrop setAppState={setAppState} setUploadedFile={setUploadedFile}/>
                    <div className='lower-container'>
                        <p className='small-text no-margin'>Powered by <a className='large-text no-margin parabole roex-blue no-underline' target="_blank" rel="noopener noreferrer" href='https://www.roexaudio.com?utm_source=mixcheckstudio&utm_medium=mainsite'>roex</a></p>
                        <SocialsList />
                    </div>
                </div>

            </div>
            <div className='single-page'>
                <AboutSection />
            </div>
            <Footer />
        </>
        
    )
}

export default Home