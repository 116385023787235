import './styles.css'

import MailingList from '../MailingList';
import React from 'react';

function AboutSection() {

    return (
        <div className='about-page-container'>
            <div className='mailing-list-wrapper'>
                <MailingList />
            </div>
            <div className='about-text-container'>
                <p className='medium-text no-margin medium-opacity'>
                            Welcome to Mix Check Studio – your one-stop destination for perfecting your mixing and mastering skills! Whether you're a musician or an audio enthusiast, if you've ever asked yourself "How can I improve my mixing?", you're in the right place. Our web app harnesses the power of advanced AI technology to analyse your audio files in WAV or MP3 format, offering accurate and actionable feedback that will help you refine your mixes and masters.
                    <br />
                    <br />
                    <span className='bold'>How does it work?</span>
                    <br />
                    <br />

                    <span className='bold'>Step 1.</span> Drag and drop your audio file or use the file selector to upload a WAV or MP3 track you need mixing or mastering assistance with.
                    <br />
                    <br />
                    <span className='bold'>Step 2.</span> Indicate whether the track is a mixed or a mastered track, enabling us to provide more precise advice.
                    <br />
                    <br />
                    <span className='bold'>Step 3.</span> Specify the musical style or genre that best describes your mixed or mastered track. If you're unsure, select 'unknown' and our AI will make an educated guess. If you believe a particular musical style is missing from the list, please let us know through the feedback button at the bottom right-hand side of the screen.
                    <br />
                    <br />
                    <span className='bold'>Step 4.</span> Your audio is securely uploaded to our servers, where we employ sophisticated algorithms and AI to analyse your track. Rest assured, your privacy is important to us; we do not retain your audio. Your audio is deleted once we have analysed it. However, we do store anonymised analysis results, such as the track metrics, which will be displayed once your track is submitted.
                    <br />
                    <br />
                    <span className='bold'>Step 5.</span> After completing the analysis, we'll provide you with feedback to help you improve your mix or master. Remember, mixing and mastering is an art form, and all feedback is subjective. Ultimately, it's up to you to decide what feels right. Trust your ears!
                    <br />
                    <br />
                    <span className='bold'>Step 6.</span> (Optional) Let us know what you think by clicking on the ‘feedback’ button at the bottom right-hand side of the screen. We also have a Discord channel if you want to come and hang out there.
                    <br />
                    <br />
                            At <span><a className='medium-text' href='https://www.roexaudio.com/'>RoEx</a></span>, we're dedicated to helping you become a better mixer. Our app is completely free, offering valuable insights to enhance your mixes without stretching your budget.
                </p>
            </div>
        </div>
      
    )
}

export default AboutSection