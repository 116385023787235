import './styles.css'

import SocialsList from '../SocialsList'

interface FooterProps {
    altBackgroundColour?: boolean
}

function Footer ({altBackgroundColour}: FooterProps) {

    return (
        <div className={ altBackgroundColour? 'footer-container grey-background' : 'footer-container'}>
            <p className='small-text no-margin'>info@roexaudio.com</p>
            <SocialsList />
            <p className='small-text no-margin'>©2023 by RoEx</p>
            <p className='small-text no-margin'>
                <span>
                    <a className='small-text no-margin' href='https://app.termly.io/document/terms-of-service/fef9b4e5-9da0-42d0-9d1c-c8a7a523c615' target="_blank" rel="noopener noreferrer">
                        Terms 
                    </a>
                </span> 
                {' & '}  
                <span>
                    <a className='small-text no-margin' href='https://app.termly.io/document/privacy-policy/61d5e01b-10c8-4602-a02d-d66d843efa28' target="_blank" rel="noopener noreferrer">
                        Privacy 
                    </a>
                </span>
            </p>
        </div>
    )
}

export default Footer