import './styles.css'

import { Diagnosis } from '../../shared/interfaces';
import Plot from 'react-plotly.js';
import React from 'react';
import { Tooltip } from "react-tooltip";

interface TonalGraphProps {
    resultsData: Diagnosis
}

function prepareTooltip():string {
    return "Evaluates sound characteristics by measuring A-weighted energy across 4 frequency bands - Bass (60-250Hz), Low-mids (250-2kHz), High-mids (2-8kHz), and Highs (8-20kHz)."
}

function TonalGraph({resultsData}: TonalGraphProps) {

    const layout = {
        plot_bgcolor: 'transparent',
        paper_bgcolor: 'transparent',
        autosize: true,
        zoom: false,
        margin: {
            t: 0,
        },
        xaxis: {
            showgrid: false,
            fixedrange: true,
            title: {
                text: 'Tonal Profile',
                font: {
                    family: 'Inter-Light',
                    color: '#878787',
                    size: 14,
                    
                },
            },
        },
        yaxis: {
            showgrid: false,
            fixedrange: true,
            categoryarray: ["LOW", "MEDIUM", "HIGH"],
            autorange: false,
        },
    }

    return (
        <div 
            data-tooltip-id="tonal-tooltip" 
            data-tooltip-content={prepareTooltip()}
            data-tooltip-place="top"
            className='tonal-graph'
        >
            <Plot
                data={[{
                    x: ['bass', 'lower-mids', 'upper-mids', 'highs'],
                    y: [resultsData.tonal_profile.bass_frequency,
                        resultsData.tonal_profile.low_mid_frequency,
                        resultsData.tonal_profile.high_mid_frequency,
                        resultsData.tonal_profile.high_frequency
                    ],
                    type: 'scatter',
                    mode: 'gauge+delta',
                    marker: { color: '#a8e4f4' },
                    showlegend: false,
                    showscale: false,
                    line: {
                        shape: 'spline',
                    },
                }]}

                layout={layout}
                style={{ width: '100%', height: '220px', marginRight: '15px', borderRadius: '10px'}}
                config={{ responsive: true, displayModeBar: false, editable: false, scrollZoom: false}}
            />
            <Tooltip id="tonal-tooltip" className='tonal-popup-content' />
        </div>
        
        
      
    )
}

export default TonalGraph