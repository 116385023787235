import './styles.css'
import '../../shared/styles/typography.css'
import '../../shared/styles/spacing.css'
import '../../shared/styles/colours.css'

function Error () {


    return (
        <div className='page-container'>
            <div className='main-content'>
                <p className='medium-text'>An Error has Occured</p>
            </div>
        </div>
    )
}

export default Error