import './styles.css'

import React, { useEffect, useState } from 'react';

import Papa from 'papaparse';

function Carousel() {
    
    const [rows, setRows] = useState([]);
    const [currentRow, setCurrentRow] = useState(0);

    const interval = setInterval(() => {
        // Increment the current row index, wrapping around to 0 if necessary
        setCurrentRow(currentRow => (currentRow + 1) % rows.length);
    }, 20000); // Update once every 20 seconds

    async function fetchCsv() {
        const response = await fetch('tips.csv');
        const reader = response.body?.getReader();
        const result = await reader?.read();
        const decoder = new TextDecoder('utf-8');
        const csv =  decoder.decode(result?.value);
        return csv;
    }
    
    async function getData() {
        const csv = Papa.parse(await fetchCsv());
        setRows(csv.data as never)
    }

    // executes on component mount
    useEffect(() => {        
        getData()
        return () => clearInterval(interval);
    }, [rows.length])
  

    return (
        <div className='carousel-container'>
            <p className='small-text bold'>Mix & Mastering Tips</p>
            <p className='small-text centre-align off-grey'>
                {rows[currentRow]}
            </p>
        </div>
    )
}

export default Carousel