import './styles.css'
import '../../shared/styles/typography.css'
import '../../shared/styles/spacing.css'
import '../../shared/styles/colours.css'

import { useEffect, useState } from 'react'

import { AppState } from '../../App'
import Papa from 'papaparse'

interface GenreRow {
    UI: string;
    API: string;
  }


interface MixMasterProps {
    setAppState: React.Dispatch<React.SetStateAction<AppState>>
    setMusicalStyle: React.Dispatch<React.SetStateAction<string>>
}

function Genre ({setAppState, setMusicalStyle}: MixMasterProps) {

    const [genres, setGenres] = useState<GenreRow[]>([]);

    useEffect(() => {
        
        async function getData() {
            try {
                const csv = Papa.parse(await fetchCsv());
                const rows: GenreRow[] = csv.data.map((row: any) => {
                    return {
                        UI: row[0],
                        API: row[1],
                    };
                });
                setGenres(rows);
            } catch (error) {
                console.error('Error fetching CSV:', error);
            }
        }
        
        async function fetchCsv() {
            const response = await fetch('genres.csv');
            const reader = response.body?.getReader();
            const result = await reader?.read();
            const decoder = new TextDecoder('utf-8');
            const csv =  decoder.decode(result?.value);
            return csv;
        }

        getData()

    }, [])

    return (
        <div className='genre-page-container'>
            <div className='genre-pop-up-container'>
                <p className='medium-text'>Select Genre</p>
                <div className='genre-buttons-container'>
                    {genres.map(({ UI, API }) => (
                        <div key={API} className='genre-button' onClick={() => {
                            setMusicalStyle(API)
                            setAppState(AppState.UPLOADING)
                        }}>
                            <p className='small-text no-margin bold'>{UI}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Genre