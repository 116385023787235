import { Diagnosis, defaultDiagnosisObj } from './shared/interfaces'
import React, { useState } from 'react';

import Completed from './pages/Completed';
import Diagnosing from './pages/Diagnosing'
import ErrorPage from './pages/Error';
import Genre from './pages/Genre';
import Home from './pages/Home';
import MixMaster from './pages/MixMaster';

/**
 * Possible states of the application
 */
export enum AppState {
    DEFAULT = 'default',
    UPLOADING = 'uploading',
    DIAGNOSING = 'diagnosing',
    COMPLETED = 'completed',
    MIX_MASTER = 'mix_master',
    GENRE = 'genre',
    ERROR = 'error'
}


function App() {    
    
    const [appState, setAppState] = useState<AppState>(AppState.DEFAULT)
    const [file, setUploadedFile] = useState<File>();
    const [response, setResponse] = useState<Diagnosis>(defaultDiagnosisObj)
    const [isMix, setIsMix] = useState<boolean>(true)
    const [musicalStyle, setMusicalStyle] = useState<string>('')


    /**
     * Renders page corresponding to the current State
     * of the app.
     * @returns  
     */
    function renderCurrentStatePage() {
        if(appState === AppState.DIAGNOSING || appState === AppState.UPLOADING ) {
            return <Diagnosing 
                setResponse={setResponse} 
                file={file} 
                setAppState={setAppState} 
                isMix={isMix} 
                appState={appState} 
                musicalStyle={musicalStyle}
            />
        } else if(appState === AppState.COMPLETED) {
            return <Completed 
                setAppState={setAppState} 
                resultsData={response} 
                file={file}
            />
        } else if (appState === AppState.MIX_MASTER) {
            return <MixMaster
                setAppState={setAppState}
                setIsMix={setIsMix}
            />
        } else if (appState === AppState.ERROR) {
            return <ErrorPage />
        } else if (appState === AppState.GENRE) {
            return <Genre 
                setAppState={setAppState} 
                setMusicalStyle={setMusicalStyle}
            /> 
        }
        else {
            return <Home
                setAppState={setAppState}
                setUploadedFile={setUploadedFile}
            />  
        }
    }

    return (
        <div>
            { renderCurrentStatePage()}
        </div>
    )
}

export default App;
