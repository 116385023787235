import './styles.css'
import '../../shared/styles/colours.css'

import React, { useState } from 'react';

import { signUpToMailingList } from '../../helper/client';

function MailingList() {

    const [isSignedUp, setIsSignedUp] = useState<boolean>(false)
    const [email, setEmail] = useState('');

    // Handle feedback comment change.
    const handleEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEmail(event.target.value);
    };

    const submitEmail = (email_address: string) => {
        if(email_address !== ''){
            signUpToMailingList(email_address)
            setIsSignedUp(true)
            setEmail('')
        }
    }

    return (
        <div className='mailing-container'>
            { isSignedUp ? 
                <>
                    <p className='small-text white no-margin no-wrap' >You have been added to our mailing list, look out for updates from RoEx.</p>
                </> :
                <>
                    <textarea className='email-input medium-text no-margin centered-input' placeholder='Enter email for updates...' onChange={handleEmailChange} rows={1} maxLength={100}/>
                    <button 
                        className={ email !== '' ? 'sign-up-button' : 'sign-up-button grey-light pointer'}
                        onClick={() => submitEmail(email)}
                    >
                        <p className='small-text black bold no-margin no-wrap' >Subscribe</p>
                    </button>
                </>
            }
        </div>
    )
}

export default MailingList