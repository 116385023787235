import './styles.css'
import '../../shared/styles/typography.css'
import '../../shared/styles/spacing.css'
import '../../shared/styles/colours.css'

import { AppState } from '../../App'
import { Tooltip } from "react-tooltip";

interface MixMasterProps {
    setAppState: React.Dispatch<React.SetStateAction<AppState>>
    setIsMix: React.Dispatch<React.SetStateAction<boolean>>
}

function mixToolTip():string {
    return "Your track has been mixed already and is ready to be sent for mastering."
}

function masterToolTip():string {
    return "Your track has been mastered and is ready for release."
}


function MixMaster ({setAppState, setIsMix}: MixMasterProps) {

    return (
        <div className='page-container'>
            <div className='pop-up-container'>
                <p className='medium-text'>Is your track a <span 
                    data-tooltip-id="mix-tooltip" 
                    data-tooltip-content={mixToolTip()}
                    className='pointer bold'
                    data-tooltip-place="top">Mix
                    <Tooltip id="mix-tooltip" className='popup-content' />
                </span> or <span 
                    data-tooltip-id="master-tooltip" 
                    data-tooltip-content={masterToolTip()}
                    className='pointer bold'
                    data-tooltip-place="top">Master
                    <Tooltip id="master-tooltip" className='popup-content' />
                </span> ?</p>
                <div className='buttons-container'>
                    <div className='mix-button margin-right'
                        onClick={() => {
                            setIsMix(true)
                            setAppState(AppState.GENRE)
                        }}
                    >
                        <p className='medium-text no-margin'>Mix</p>
                    </div>
                    <div className='mix-button'
                        onClick={() => {
                            setIsMix(false)
                            setAppState(AppState.GENRE)
                        }}
                    >
                        <p className='medium-text no-margin'>Master</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MixMaster