import './styles.css'

import React from 'react';

function SocialsList() {

    return (
        <div className='socials-container'>
            <a href='https://discord.com/invite/8R5FM4mbuF' target="_blank" rel="noopener noreferrer">
                <img className='social-logos' src={'./discord.png'}/>
            </a>
            <a href='https://www.linkedin.com/company/roexaudio/' target="_blank" rel="noopener noreferrer">
                <img className='social-logos' src={'./linkedin.png'}/>
            </a>
            <a href='https://www.instagram.com/roexaudio/' target="_blank" rel="noopener noreferrer">
                <img className='social-logos' src={'./instagram.png'}/>
            </a>
            <a href='https://twitter.com/roexaudio' target="_blank" rel="noopener noreferrer">
                <img className='social-logos no-margin' src={'./twitter.png'}/>
            </a>
        </div>
      
    )
}

export default SocialsList