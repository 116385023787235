import './styles.css'
import '../../shared/styles/typography.css'
import '../../shared/styles/spacing.css'
import '../../shared/styles/colours.css'
import 'react-tooltip/dist/react-tooltip.css'

import { useRef, useState } from 'react'

import { AppState } from '../../App'
import { Diagnosis } from '../../shared/interfaces'
import Footer from '../../components/Footer'
import MediaPlayer from '../../components/MediaPlayer'
import { Rating } from 'react-simple-star-rating'
import ResultItem from '../../components/ResultItem'
import TonalGraph from '../../components/TonalGraph'
import { TypeAnimation } from 'react-type-animation';
import { sendFeedback } from '../../helper/client'

interface CompletedPageProps {
    setAppState: React.Dispatch<React.SetStateAction<AppState>>
    resultsData: Diagnosis
    file: any
}

function Completed ({ resultsData, setAppState, file }: CompletedPageProps) {

    const [givingFeedback, setGivingFeedback] = useState<boolean>(false)
    const [rating, setRating] = useState(0)
    const [comment, setComment] = useState('');

    interface childRefInterface {
        handleStop: () => void;
      }


    const handleBackClick = () => {
        setAppState(AppState.DEFAULT);
        if (childRef.current) {
            childRef.current.handleStop();
        }
    }

    // Catch Rating value
    const handleRating = (rate: number) => {
        setRating(rate)
    }

    // Handle feedback comment change.
    const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(event.target.value);
    };


    const givenFeedback = ():boolean => {
        return comment !== "" && rating !== 0
    }

    const childRef = useRef<childRefInterface>(null)

    return (
        <div className='comp-page-container'> 
            <div className='header-container'>
                <button className='back-button-container'>
                    <p className='small-text no-margin button-text'>
                        <img 
                            src='back-arrow.png' 
                            className='back-arrow' 
                            alt='back'
                            onClick={() => { handleBackClick() }}
                        />
                    </p>
                </button>
                <div className='name-container'>
                    <h1 className='large-text parabole no-margin'><span className='roex-blue'>mix check</span> studio</h1>
                </div>
                <div className='right-filler'>
                    <p className='small-text no-margin'>Powered by <a className='large-text no-margin parabole roex-blue no-underline' target="_blank" rel="noopener noreferrer" href='https://www.roexaudio.com?utm_source=mixcheckstudio&utm_medium=mainsite'>roex</a></p>
                </div>
            </div>
            <div className='song-name-container'>
                <p className='small-text no-margin'>
                    {file.name}
                </p>
            </div>

            <MediaPlayer file={file} ref={childRef}/>
            
            <div className='middle-container'>
                <div className='data-container'>
                    <ResultItem name='Genre' value={resultsData?.musical_style.toUpperCase()}/>
                    <ResultItem name="Sample Rate" value={resultsData?.sample_rate}/>
                    <ResultItem name='Bit Depth' value={resultsData?.bit_depth}/> 
                    <ResultItem name='Clipping' value={resultsData?.clipping}/>
                    <ResultItem name='Mono Compatibility' value={resultsData?.mono_compatible}/>
                    <ResultItem name='Integrated Loudness' value={resultsData?.integrated_loudness_lufs.toFixed(1)}/>
                    <ResultItem name='True Peak Loudness' value={resultsData?.peak_loudness_dbfs.toFixed(1)}/>
                    <ResultItem name="Phase Issues" value={resultsData?.phase_issues}/>
                    <ResultItem name='Stereo Field' value={resultsData?.stereo_field}/> 
                    <div className='tonal-container'>
                        <TonalGraph resultsData={resultsData}/>
                    </div>
                </div>  
                <div className='text-panel'>
                    <p className='small-text no-margin'>
                        <TypeAnimation
                            sequence={[
                                resultsData.summary
                            ]}
                            wrapper="span"
                            cursor={true}
                            speed={98}

                        />
                    </p>
                </div>    
            </div>  
            <div className='large-margin-bottom large-margin-top'>
                <p className='medium-text no-margin center'>Love Mix Check Studio? Elevate your sound effortlessly with <span><a className='parabole roex-blue no-margin no-underline medium-text-alt' href='https://automix.roexaudio.com' target='_blank'>Automix.</a></span></p>

            </div>
           
            <div 
                className={ givingFeedback ? 'feedback-container' : 'feedback-button'} 
                onClick={ () => {!givingFeedback ? setGivingFeedback(true) : null }}
            >
                {
                    givingFeedback ?
                        <div>
                            <div className='close-button no-padding' onClick={() => {setGivingFeedback(false)}}>
                                <p className='no-margin no-padding bold'>-</p>
                            </div>
                            <textarea name="name" className='feedback-comments-container medium-text' maxLength={600} onChange={handleCommentChange} placeholder={'Feedback...'}/>
                            <div className='submit-container'>
                                <Rating
                                    fillColor='#FFD700'
                                    size={30}
                                    onClick={handleRating}
                                />
                                <button className={givenFeedback() ? 'submit-button': 'submit-button-disabled'} 
                                    disabled={!givenFeedback()}
                                    onClick={ () => {
                                        setGivingFeedback(false)
                                        setRating(0)
                                        sendFeedback({comment: comment, rating: rating})
                                        setComment('')
                                    }}>
                                    <p className='small-text black bold' >Submit</p>
                                </button>
                            </div>
                        </div>
                        :
                        <p className='small-text no-margin' id="feedback-button">Give Feedback</p>
                }
            </div>
            <div className='single-page'>
                <div className='about-page-container'>
                    <div className='about-text-container'>
                        <p className='medium-text no-margin medium-opacity'>
                            Welcome to Mix Check Studio – your one-stop destination for perfecting your mixing and mastering skills! Whether you're a musician or an audio enthusiast, if you've ever asked yourself "How can I improve my mixing?", you're in the right place. Our web app harnesses the power of advanced AI technology to analyse your audio files in WAV or MP3 format, offering accurate and actionable feedback that will help you refine your mixes and masters.
                            <br />
                            <br />
                            <span className='bold'>How does it work?</span>
                            <br />
                            <br />

                            <span className='bold'>Step 1.</span> Drag and drop your audio file or use the file selector to upload a WAV or MP3 track you need mixing or mastering assistance with.
                            <br />
                            <br />
                            <span className='bold'>Step 2.</span> Indicate whether the track is a mixed or a mastered track, enabling us to provide more precise advice.
                            <br />
                            <br />
                            <span className='bold'>Step 3.</span> Specify the musical style or genre that best describes your mixed or mastered track. If you're unsure, select 'unknown' and our AI will make an educated guess. If you believe a particular musical style is missing from the list, please let us know through the feedback button at the bottom right-hand side of the screen.
                            <br />
                            <br />
                            <span className='bold'>Step 4.</span> Your audio is securely uploaded to our servers, where we employ sophisticated algorithms and AI to analyse your track. Rest assured, your privacy is important to us; we do not retain your audio. Your audio is deleted once we have analysed it. However, we do store anonymised analysis results, such as the track metrics, which will be displayed once your track is submitted.
                            <br />
                            <br />
                            <span className='bold'>Step 5.</span> After completing the analysis, we'll provide you with feedback to help you improve your mix or master. Remember, mixing and mastering is an art form, and all feedback is subjective. Ultimately, it's up to you to decide what feels right. Trust your ears!
                            <br />
                            <br />
                            <span className='bold'>Step 6.</span> (Optional) Let us know what you think by clicking on the ‘feedback’ button at the bottom right-hand side of the screen. We also have a Discord channel if you want to come and hang out there.
                            <br />
                            <br />
                            At <span><a className='medium-text' href='https://www.roexaudio.com?utm_source=mixcheckstudio&utm_medium=mainsite'>RoEx</a></span>, we're dedicated to helping you become a better mixer. Our app is completely free, offering valuable insights to enhance your mixes without stretching your budget.
                        </p>
                    </div>
                </div>
            </div>
            <Footer altBackgroundColour={false}/>
        </div> 
    )
}

export default Completed