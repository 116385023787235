import { ApiResponse, Feedback, SignedUrlResponse } from '../shared/interfaces';

import axios from 'axios'
import configuration from '../config';

const { apiKey, apiUrl } = configuration

enum ReqMethod {
    POST = 'POST',
    GET = 'GET',
    PUT = 'PUT'
}

/**
 * Worker function to handle http requests
 * 
 * @param method 
 * @param url 
 * @param body 
 * @param headers 
 * @returns 
 */

async function makeRequest (
    method: ReqMethod,
    url: string,
    body: unknown,
    headers?: Record<string, string>
): Promise<ApiResponse> {

    const reqHeaders = {
        'x-mixcheck-api-key': apiKey,
        ...headers
    }

    let res: any

    try {
        res =  await axios.request({
            method, url, data: body, headers: reqHeaders,
        });
    } catch (e: unknown) {
        console.log(e)
    }

    return res.data
}


/**
 * Gets a signed url that can be used to upload a file
 * to our bucket.
 * @param body 
 * @returns 
 */

export async function getSignedUrl(body: {fileName: string, audioType: string}): Promise<SignedUrlResponse> {
    const url = `${apiUrl}/api/signed_url`;
    const { error, payload } = await makeRequest(ReqMethod.POST, url, body)

    if (error) {
        console.log("Encountered Error")
        throw new Error;
    }

    return payload as SignedUrlResponse
}


/**
 * Executes the diagnosis of a track
 * @param body 
 * @returns ApiResponse
 */
export async function mixdiagnosis(body: {publicUrl: string, isMaster: boolean, musicalStyle: string}): Promise<ApiResponse> {

    const url = `${apiUrl}/api/diagnosis`;

    const preparedBody = {
        mixDiagnosisData: {
            audioFileLocation: body.publicUrl,
            isMaster: body.isMaster,
            musicalStyle: body.musicalStyle
        }
    }
    
    const res = await makeRequest(ReqMethod.POST, url, preparedBody);
        
    return res
}


/**
 * Uploads track to our bucket
 * @param signedUrl 
 * @param body 
 */
export async function uploadFile(signedUrl: string, body: {file: File}) {

    await axios.put(signedUrl, body.file, {
        headers: {
            "Content-Type": body.file.type,
        },
    });
    
}

/**
 * Sends feedback to our db collection
 * @param feedback 
 */
export async function sendFeedback(feedback: Feedback){

    const url = `${apiUrl}/api/feedback`;

    const { error } = await makeRequest(ReqMethod.POST, url, feedback);

    if (error) {
        console.log("Encountered Error")
        throw new Error;
    }
}


/**
 * Adds email to our mailing list
 * @param email
 */
export async function signUpToMailingList(email_address: string){

    const url = `${apiUrl}/api/mailing_list`;

    if(!checkMailFormat(email_address)) return false
    
    const { error } = await makeRequest(ReqMethod.POST, url, {email_address});

    if (error) {
        console.log("Encountered Error")
        throw new Error;
    }
}


/**
 * Checks if the email provided is in the correct format
 * @param email 
 * @returns 
 */
function checkMailFormat(email: string) {
    //eslint-disable-next-line
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(email.match(mailformat)) return true;
    return false;
}
