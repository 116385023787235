import './styles.css'

import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"

import WaveSurfer from 'wavesurfer.js';

interface MediaPlayerProps {
    file: File
}

function MediaPlayer ({file}: MediaPlayerProps, ref: any) {

    const [isPlaying, setIsPlaying] = useState<boolean>(false)
    const [wave, setWave] = useState<WaveSurfer>()

    useImperativeHandle(ref, () => ({
        handleStop
    }));

      
    /**
     * When the play/pause button is clicked.
     */
    const handleClick = () => {
        wave?.playPause()
        setIsPlaying(!isPlaying)
    } 

    /**
     * When the stop button is clicked.
     */
    const handleStop = () => {
        wave?.stop()
        setIsPlaying(false)
    } 


    // executed on component mount
    useEffect(() => {
        
        const reader = new FileReader();
        reader.readAsDataURL(file);
    
        reader.onload = () => {
            
            const audioUrl = reader.result as string; // extract data url
            const audio = new Audio(audioUrl);
            
            const waveObj = WaveSurfer.create({
                barWidth: 3,
                cursorWidth: 1,
                container: '#waveform',
                backend: 'WebAudio',
                height: 80,
                progressColor: '#a8e4f4',
                responsive: true,
                waveColor: 'white',
                cursorColor: 'transparent',
                barRadius: 4,
                splitChannels: true,
            }) // create wave object
            
            waveObj.load(audio)

            setWave(waveObj)
        }
    }, [])

    return (
        <div className='player-container'>
            <div className='media-container'>
                <div className="wave-container">
                    <div id="waveform" />
                </div>
            </div>
            <div className='control-container'>
                <button className="button-container margin-right" onClick={handleClick}>
                    <img className='play-pause-logo' src={ isPlaying ? './pause.png' : './play.png'}/>
                </button>
                <button className="button-container" onClick={handleStop}>
                    <img className='play-pause-logo' src={ isPlaying ? './stop.png' : './stop.png'}/>
                </button>
            </div>
        </div>
    
    )
}

export default forwardRef(MediaPlayer)